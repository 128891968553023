@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  font-family: 'Poppins', sans-serif !important;
}

.css-13cymwt-control  {
  border:none !important;
  cursor: pointer !important;
}

.css-qbdosj-Input {
  cursor: pointer !important;
}

.video-container {
  width: 100%;
  margin: 0 auto;
}